import { webstore } from '@/api';
import { getConfigEntry } from '@/api/config';
import {
  BaseChoice,
  BaseVariation,
  DY_SESSION_ID_COOKIE,
  DY_USER_ID_COOKIE,
  RecommendationsSlot,
} from '@/lib/personalization/dynamicYield';
import { getCookie } from '@/utils/isomorphic/cookie';

const baseUrl = import.meta.env.VITE_DY_CLIENT_SIDE_ENGAGEMENT_URI;
const requestTimeoutLimit = Number(import.meta.env.VITE_DY_CLIENT_SIDE_TIMEOUT);

interface DecisionEngagement {
  type: 'CLICK';
  decisionId: BaseChoice['decisionId'];
}
interface RecommendationsEngagement {
  type: 'SLOT_CLICK';
  slotId: RecommendationsSlot['slotId'];
}
function buildRequestBody(
  decisionId: BaseChoice['decisionId'],
  slotId?: RecommendationsSlot['slotId'],
) {
  const sessionId = getCookie(DY_SESSION_ID_COOKIE, false);
  const userId = getCookie(DY_USER_ID_COOKIE, false);

  const engagements: (DecisionEngagement | RecommendationsEngagement)[] = [
    { type: 'CLICK', decisionId },
  ];
  if (slotId) {
    engagements.push({ type: 'SLOT_CLICK', slotId });
  }

  return {
    engagements,
    session: {
      dy: sessionId,
    },
    user: {
      dyid: userId,
      dyid_server: userId,
    },
  };
}

export async function proxiedReportEngagement(
  decisionId: BaseChoice['decisionId'],
  variationId: BaseVariation['id'],
) {
  const searchParams = new URLSearchParams();
  searchParams.append('decision_id', decisionId);
  searchParams.append('variation_id', String(variationId));
  await webstore.sessionSpecific.post('/api/dy/engagement', searchParams, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

export async function reportEngagement(
  decisionId: BaseChoice['decisionId'],
  slotId?: RecommendationsSlot['slotId'],
) {
  const apiKey = getConfigEntry('dynamic_yield').client_side.key;
  const response = await fetch(`${baseUrl}/collect/user/engagement`, {
    body: JSON.stringify(buildRequestBody(decisionId, slotId)),
    headers: {
      'Content-Type': 'application/json',
      'DY-API-Key': apiKey,
    },
    method: 'POST',
    signal: AbortSignal.timeout(requestTimeoutLimit),
  });

  if (!response.ok) {
    const message = (await response.text()) ?? `${response.status} ${response.statusText}`;
    throw new Error(`Failed to report engagement: ${message}`);
  }
}
